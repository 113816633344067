/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        common: {
            init: function() {
                // JavaScript to be fired on all pages

                $('[data-toggle="modal"]').click(function() {
                    var title = $(this).attr("data-title");
                    var link = $(this).attr("data-link");
                    var host = $(this).attr("data-host");
                    var permalink = $(this).attr("data-id");

                    $("#videoModal .modal-title").html(title);
                    $("#videoModal .addthis_output").html(
                        '<div class="addthis_sharing_toolbox" data-url="' +
                            permalink +
                            '" data-title="' +
                            title +
                            '"></div>'
                    );

                    if (host === "brandlive") {
                        $("#videoModal #videoContainer").html(
                            '<iframe frameborder="0" scrolling="no" width="100%" height="100%" allowfullscreen src="//www.ustream.tv/embed/recorded/' +
                                link +
                                '/?wmode=transparent&ub=00a4e4&lc=00a4e4&oc=ffffff&uc=ffffff&quality=best"></iframe>'
                        );
                    } else if (host === "youtube") {
                        $("#videoModal #videoContainer").html(
                            '<iframe id="vid-responsive" width="100%" height="100%" src="https://www.youtube.com/embed/' +
                                link +
                                '" frameborder="0" allowfullscreen></iframe>'
                        );
                    } else if (host === "vimeo") {
                        $("#videoModal #videoContainer").html(
                            '<iframe src="//player.vimeo.com/video/' +
                                link +
                                '?title=0&byline=0" width="100%" height="100%" style="position:absolute; top:0; left: 0;" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>'
                        );
                    }
                    addthis.layers.refresh();
                });

                $("#videoModal").on("hidden.bs.modal", function() {
                    $("#videoContainer").html("");
                    $("#videoModal .addthis_output").html("");
                });

                //Slide out menu
                $(function() {
                    $(".slide-nav.navbar .container").append(
                        $('<div class="navbar-height-col"></div>')
                    );
                    var toggler = ".navbar-toggle";
                    var navigationwrapper = ".nav-wrap";
                    var slidewidth = "293px";
                    var menuneg = "-293px";
                    var slideneg = "-293px";
                    var slidemenu = ".slidemenu";

                    $(".slide-nav").on("click", toggler, function(e) {
                        var selected = $(this).hasClass("slide-active");

                        $(slidemenu)
                            .stop()
                            .animate({
                                right: selected ? menuneg : "0px"
                            });

                        $(".navbar-height-col")
                            .stop()
                            .animate({
                                right: selected ? slideneg : "0px"
                            });

                        $(navigationwrapper)
                            .stop()
                            .animate({
                                right: selected ? "0px" : slidewidth
                            });

                        $(this).toggleClass("slide-active", !selected);
                        $(slidemenu).toggleClass("slide-active");

                        $(".navbar, body, .navbar-header").toggleClass(
                            "slide-active"
                        );
                    });
                });

                // Search Functionality
                $(function() {
                    var submitIcon = $(".searchbox-icon");
                    var inputBox = $(".search-field-header");
                    var searchBox = $(".search-form-header");
                    var isOpen = false;

                    submitIcon.click(function() {
                        if (isOpen === false) {
                            searchBox.addClass("searchbox-open");
                            inputBox.focus();
                            isOpen = true;
                        } else {
                            searchBox.removeClass("searchbox-open");
                            inputBox.focusout();
                            isOpen = false;
                        }
                    });

                    submitIcon.mouseup(function() {
                        return false;
                    });

                    searchBox.mouseup(function() {
                        return false;
                    });

                    $(document).mouseup(function() {
                        if (isOpen === true) {
                            $(".searchbox-icon").css("display", "block");
                            submitIcon.click();
                        }
                    });
                });

                $(".search-field-header").keyup(function() {
                    var inputVal = $(this).val();
                    inputVal = $.trim(inputVal).length;
                    if (inputVal !== 0) {
                        $(".searchbox-icon").css("display", "none");
                    } else {
                        $(this).val("");
                        $(".searchbox-icon").css("display", "block");
                    }
                });

                $(".template-verticalTile").click(function() {
                    window.location = $(this)
                        .find("a")
                        .attr("href");
                    return false;
                });
            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        home: {
            init: function() {
                // JavaScript to be fired on the home page

                var slider = $(".template-carousel")
                    .on("init", function(slick) {
                        $(".template-carousel").fadeIn(3000);
                    })
                    .slick({
                        dots: true,
                        arrows: false,
                        infinite: true,
                        autoplay: true,
                        autoplaySpeed: 2000,
                        speed: 500,
                        fade: true,
                        cssEase: "linear"
                    });
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        //
        brand_comparison: {
            init: function() {
                // JavaScript to be fired on the brand comparison page
                $('[data-toggle="modal"]').click(function() {
                    var description = $(this).attr("data-description");
                    var link = $(this).attr("data-link");
                    var title = $(this).attr("data-title");
                    var src = $(this).attr("data-image");
                    var label = $(this).attr("data-label");
                    $("#brandModal .content-description").html(description);
                    $("#brandModal .content-link").html(
                        '<a class="btn btn-default mar-t-35" href="' +
                            link +
                            '" target="_blank">' +
                            label +
                        '</a>'
                    );
                    $("#brandModal .modal-title").html(title);
                    $("#brandModal .body-image")
                        .children()
                        .attr({
							"src": src,
							"alt": title
                        });
                });
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        //
        page_template_template_industry_links: {
            init: function() {
                // JavaScript to be fired on the about us page
                $('[data-toggle="modal"]').click(function() {
                    var description = $(this).attr("data-description");
                    var link = $(this).attr("data-link");
                    var title = $(this).attr("data-title");
                    $("#industryModal .content-description").html(description);
                    $("#industryModal .content-link").html(
                        '<a href="' +
                            link +
                            '" target="_blank">' +
                            link +
                            "</a>"
                    );
                    $("#industryModal .modal-title").html(title);
                });
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        //
        page_template_template_howto: {
            init: function() {
                // JavaScript to be fired on the about us page
                FWP.auto_refresh = false;
                $(document).on("facetwp-loaded", function() {
                    // Scroll to the top of the page after the page is refreshed
                    $(".template-howToSearch").animate(
                        {
                            opacity: 1
                        },
                        500
                    );
                });

                $("#fwp-search").click(function() {
                    $(".template-howToSearch").animate(
                        {
                            opacity: 0
                        },
                        0
                    );
                });

                // $('#fwp-search').click(function() {
                //    var query = FWP.build_query_string();
                //    window.location.href = '/search-results/?' + query;
                //  });
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = funcname === undefined ? "init" : funcname;
            fire = func !== "";
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === "function";

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire("common");

            // Fire page-specific init JS, and then finalize JS
            $.each(
                document.body.className.replace(/-/g, "_").split(/\s+/),
                function(i, classnm) {
                    UTIL.fire(classnm);
                    UTIL.fire(classnm, "finalize");
                }
            );

            // Fire common finalize JS
            UTIL.fire("common", "finalize");
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.

function fwp_redirect() {
    var query = FWP.build_query_string();
    window.location.href = "/search-results/?" + query;
}

(function($) {
    var carousels = [
        ".simple-display-carousel",
        ".simple-display-carousel-two",
        ".other-video-carousel",
        ".product-item--carousel",
        ".how-to-videos-slides--carousel"
    ];
    var breakPoints = [
        [
            {
                breakpoint: 565,
                settings: { slidesToShow: 2, slidesToScroll: 2 }
            },
            {
                breakpoint: 480,
                settings: { slidesToShow: 1, slidesToScroll: 1 }
            }
        ],
        [
            {
                breakpoint: 1200,
                settings: { slidesToShow: 4, slidesToScroll: 4 }
            },
            { breakpoint: 992, settings: { slide: "unslick" } }
        ]
    ];

    function sliders(
        carousel,
        dots,
        infinite,
        show,
        scroll,
        arrows,
        autoplay,
        fade,
        responsive
    ) {
        $(carousel)
            .on("init reInit afterChange", function(
                event,
                slick,
                currentSlide,
                nextSlide
            ) {
                $(carousel).fadeIn(3000);
            })
            .slick({
                dots: dots,
                infinite: infinite,
                speed: 500,
                slidesToShow: show,
                slidesToScroll: scroll,
                arrows: arrows,
                autoplay: autoplay,
                fade: fade,
                autoplaySpeed: 3000,
                responsive: responsive
            });
    }

    $(document).ready(function() {
        // Carousels for the Display How to and product 101 pages
        sliders(carousels[0], false, true, 1, 1, true, true, true, "");
        sliders(carousels[1], false, true, 1, 1, true, true, true, "");
        sliders(
            carousels[2],
            false,
            true,
            3,
            3,
            true,
            false,
            false,
            breakPoints[0]
        );
        sliders(
            carousels[3],
            false,
            false,
            5,
            5,
            true,
            false,
            false,
            breakPoints[1]
        );
        sliders(
            carousels[4],
            false,
            false,
            5,
            5,
            true,
            false,
            false,
            breakPoints[1]
        );
    });
})(jQuery); // Fully reference jQuery in this function.
